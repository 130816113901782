body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-header {
  background-color: #ee9f9f !important;
  color: #000;
}

.btn-group {
  display: flex;
  flex-flow: row;
  gap: 5px;
  width: 100%;
}

.staff_turn_wrapper {
  display: flex;
  flex-flow: row;
  justify-content: center;
  max-width: 768px;
  margin: 0 auto;
}

.staff_turn_wrapper .staff_turn_block {
  width: 100%;
  text-align: center;
  padding: 15px;
}

.staff_turn_wrapper .staff_turn_block:first-child {
  border-right: 1px solid #ddd;
}

.staff_turn_list_wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
  justify-items: center;
  min-height: 400px;
}

.staff_turn_list_wrapper .staff_turn_list_item {
  margin: 5px 0;
  display: flex;
  flex-flow: row;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
}

.staff_turn_list_wrapper .staff_turn_list_item button {
  border: 1px solid #333;
  border-radius: 15px;
  background-color: transparent;
  outline: none;
  color: #000;
  padding: 5px 15px;
  width: 200px;
  height: 50px;
  flex: 1;
}

.staff_turn_list_wrapper .staff_turn_list_item button.bell-btn {
  flex: 0;
}

.notified-time {
  font-size: 12px;
  display: block;
  font-style: oblique;
}

.staff_turn_list_wrapper .staff_turn_list_item button.bell-btn.disabled {
  user-select: none;
  pointer-events: none;
  touch-action: none;
  background-color: #ddd;
  border: none;
  cursor: default;
}

.staff_turn_list_wrapper .staff_turn_list_item button:hover {
  background-color: #ee9f9f;
}

@media (max-width: 768px) {
  .staff_turn_wrapper {
    max-width: unset;
    width: 100%;
  }
  .staff_turn_list_wrapper .staff_turn_list_item button {
    width: 150px;
  }
}

@media (max-width: 400px) {
  .staff_turn_list_wrapper .staff_turn_list_item button {
    width: 100px;
  }
}
